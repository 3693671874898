import Vue, { set } from 'vue';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
interface OpenOptions {
  component: string;
  overlay?: boolean;
  floatingButton?: boolean;
  duration?: number;
  fullScreen?: boolean;
  preventScroll?: boolean;
  transition?: unknown;
  events?: Array<{ value: string; callback(...args: Array<unknown>): unknown }>;
  attrs?: Record<string, unknown>;
}

interface ModalPlugin {
  open(options: OpenOptions): void;
  close(): void;
  change(prop: string, value: unknown): void;
  changeAttrs(prop: string, value: unknown): void;
  increaseKey(): void;
}

export default defineNuxtPlugin(({ app }, inject) => {
  const modal = {
    data: Vue.observable({
      component: null,
      attrs: null,
      onClose: null,
      transition: '',
      overlay: true,
      duration: 500,
      scrollbar: null,
      floatingButton: false,
      fullScreen: false,

      keyReloadsPage: 2
    }),

    event: new Vue(),

    open (options: OpenOptions): void {
      this.event.$emit('open', options);
    },

    close (): void {
      this.event.$emit('close');
    },

    change (prop: string, value: unknown): void {
      set(this.data, prop, value);
    },

    changeAttrs (prop: string, value: unknown): void {
      if (this.data.attrs) {
        set(this.data.attrs, prop, value);
      }
    },

    // Используется для обновления страниц
    // Например, на странице делаем watch на keyReloadsPage
    // и при изменении keyReloadsPage вызываем fetch
    increaseKey (): void {
      set(this.data, 'keyReloadsPage', this.data.keyReloadsPage + 1);
    }
  };

  app.$modal = modal;
  inject('modal', modal);
});

declare module 'vue/types/vue' {
  interface Vue {
    $modal: ModalPlugin;
  }
}
