import type {
  ActionTree,
  MutationTree
} from 'vuex';

import {
  Flat as BaseFlat
} from '../types/Flat';

interface Flat extends BaseFlat {
  status: number;
  pricePerMeter: number;
  bookingDays: number;
}

interface State {
  flats: Array<Flat>;
}

const getDefaultState = ():State => {
  return {
    flats: []
  };
};

export const state = (): State => getDefaultState();


export type RootState = unknown;

export const actions: ActionTree<State, RootState> = {
  reset (
    { commit }
  ): void {
    commit('RESET');
  },

  setFlats (
    { commit },
    payload: Array<Flat>
  ):void {
    commit('SET_FLATS', payload);
  }
};

export const mutations: MutationTree<State> = {
  RESET (
    state
  ): void {
    Object.assign(state, getDefaultState());
  },

  SET_FLATS (
    state: State,
    payload: Array<Flat>
  ): void {
    state.flats = payload;
  }
};
