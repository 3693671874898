import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import {
  AgentProfile,
  AgencyProfile,
  AgencyProfileFile,
  PatchAgentProfileBody,
  ChangePasswordBody
} from './types';

const STAGES = {
  EDIT_PROFILE: 'EditProfile',
  EDIT_PROFILE_AGENCY: 'EditProfileAgency',
  CHANGE_PASSWORD: 'ChangePassword',
  CHANGE_PASSWORD_SUCCESS: 'ChangePasswordSuccess',
  RESTORE_PASSWORD: 'RestorePassword',
  RESTORE_PASSWORD_SUCCESS: 'RestorePasswordSuccess',
  FIRED_CONFIRM: 'FiredConfirm',
  FIRED_SUCCESS: 'FiredSuccess'
} as const;


interface Agency {
  name?: string;
  files?: Array<AgencyProfileFile>;
}
type Profile = Partial<AgentProfile> & { password?: string }
type GetAgencyStrategy = 'represes' | 'agents'

export const useAgentProfileStore = defineStore('agentProfile', () => {
  const {
    $axios,
    $auth,
    $api,
    $sentry,
    $router
  } = useContext();

  const profile = ref<Profile>({
    name: undefined,
    surname: undefined,
    patronymic: undefined,
    email: undefined,
    phone: undefined,
    agency: undefined,
    password: '*'.repeat(16)
  });

  const agency = ref<Agency>({
    name: undefined,
    files: []
  });

  const stateProfile = ref<typeof STAGES[keyof typeof STAGES]>(STAGES.EDIT_PROFILE);

  async function getAccount (): Promise<void> {
    try {
      const url = `/api/${ $auth.$state.strategy }/profile`;
      const {
        name,
        surname,
        patronymic,
        email,
        phone,
        agency
      } = await $axios.$get<AgentProfile>(url);

      profile.value.name = name;
      profile.value.surname = surname;
      profile.value.patronymic = patronymic;
      profile.value.email = email;
      profile.value.phone = phone;
      profile.value.agency = agency;
    } catch (error) {
      console.error('🚀 ~ file: agentProfile ~ getAccount ~ error', error);
      $sentry.captureException(error);
    }
  }


  async function getAgencyAccount (strategy: GetAgencyStrategy): Promise<void> {
    try {
      const url = `/api/agencies/${ strategy }/profile`;
      const { name, files } = await $axios.$get<AgencyProfile>(url);

      agency.value.name = name;
      agency.value.files = files;
    } catch (error) {
      console.error('🚀 ~ file: agentProfile ~ getAgencyAccount ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function updatePassword (payload: ChangePasswordBody): Promise<void> {
    try {
      const url = `/api/${ $auth.$state.strategy }/change_password`;
      await $axios.$patch(url, payload);
    } catch (error: unknown) {
      console.error('🚀 ~ file: agentProfile ~ updatePassword ~ error', error);
      $sentry.captureException(error);
      throw error;
    }
  }

  async function updateProfile (payload: PatchAgentProfileBody): Promise<void> {
    try {
      const url = `/api/${ $auth.$state.strategy }/profile`;
      await $axios.$patch(url, payload);
      await $auth.fetchUser();
    } catch (error) {
      console.error('🚀 ~ file: agentProfile ~ updateProfile ~ error', error);
      $sentry.captureException(error);
      throw error;
    }
  }

  async function resetPasswordByEmail (payload: { email: string }): Promise<void> {
    try {
      const url = `/api/${ $auth.$state.strategy }/email_reset`;
      await $axios.$post(url, payload);
    } catch (error) {
      console.error('🚀 ~ file: agentProfile ~ resetPasswordByEmail ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function updateAgency (payload: {
    name: string;
    'procuratory_files': Array<unknown>;
    'company_files': Array<unknown>;
  }): Promise<void> {
    try {
      await $axios.$patch('/api/agencies/profile', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      await getAgencyAccount($auth.$state.strategy);
    } catch (error) {
      console.error('🚀 ~ file: agentProfile ~ updateAgency ~ error', error);
      $sentry.captureException(error);
      throw error;
    }
  }

  async function joinAgency (inn: string): Promise<void> {
    try {
      const { strategy } = $auth.$state;
      const url = $api.account.joinAgency(strategy);
      await $axios.$post(url, {
        agencyInn: inn
      });

      if (strategy === 'represes') {
        await $router.push({ query: {} });
        await $auth.logout();
      }
    } catch (error) {
      console.error('🚀 ~ file: agentProfile ~ joinAgency ~ error', error);
      $sentry.captureException(error);
      throw error;
    }
  }

  function setStage (stage: keyof typeof STAGES): void {
    stateProfile.value = STAGES[stage];
  }

  return {
    profile,
    agency,
    stateProfile,
    getAccount,
    getAgencyAccount,
    updatePassword,
    updateProfile,
    resetPasswordByEmail,
    updateAgency,
    joinAgency,
    setStage
  };
});
