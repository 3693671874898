import { useChessboardStore } from '@strana-artw/chessboard';
import { useBuildingsStore } from '@/store/buildings';

// TODO скорее всего можно избавиться от стора и использовать в рамках одной страницы передавая через пропсы/provide
export const STAGE_STEPS = {
  RealtyDataStep: 'RealtyDataStep',
  BookingParamsStep: 'BookingParamsStep',
  BookingResultStep: 'BookingResultStep'
};
// fixme: объединить steps, modalSteps, STAGES
const steps = [
  {
    component: 'SelectedDealsBookingProjects',
    label: 'Выберите ЖК'
  },
  {
    component: 'SelectedDealsBookingChessboard'
  }
];

const modalSteps = [
  STAGE_STEPS.RealtyDataStep,
  STAGE_STEPS.BookingParamsStep,
  STAGE_STEPS.BookingResultStep
];

const STAGES = {
  SelectedDealsBookingProjects: 1,
  // Это значение не используется напрямую, занесено для прозрачности
  SelectedDealsBookingChessboard: [2, 3],
  RealtyDataStep: 4,
  BookingParamsStep: 5
};

export const state = () => ({
  currentStepIndex: 0,
  currentModalStepIndex: 0,
  bookingResult: {}
});

export const getters = {
  currentStep: (state) => steps[state.currentStepIndex],
  currentModalStep: (state) => modalSteps[state.currentModalStepIndex],
  stage: () => (value) => STAGES[value],
  stageCount: () => Object.values(STAGES).reduce((previousValue, currentValue) => {
    if (Array.isArray(currentValue)) {
      return previousValue + currentValue.length;
    }

    return ++previousValue;
  }, 0)
};

export const actions = {
  setInitialValue ({ commit }) {
    commit('SET_INITIAL_DATA');

    const chessboardStore = useChessboardStore();

    chessboardStore.resetFilter();
    chessboardStore.updateFilter('project', '');
    chessboardStore.updateFilter('building', '');
    chessboardStore.setActiveFlatId('');

    useBuildingsStore().reset();
  },

  setModalInitialValue ({ commit }) {
    commit('SET_MODAL_INITIAL_DATA');
  },

  setCurrentStep ({ commit }, payload) {
    commit('SET_CURRENT_STEP_INDEX', steps
      .map(({ component }) => component)
      .indexOf(payload)
    );
  },

  setCurrentModalStep ({ commit }, payload) {
    commit('SET_CURRENT_MODAL_STEP_INDEX', modalSteps
      .indexOf(payload)
    );
  },

  setBookingResult ({ commit }, payload) {
    commit('SET_BOOKING_RESULT', payload);
  },

  async createObject (_, payload) {
    try {
      const {
        propertyId,
        type,
        bookingTypeId
      } = payload;

      if (!propertyId) {
        throw new Error('global_id is undefined');
      }
      const url = '/api/properties';
      const createObjectData = await this.$axios.$post(url, {
        global_id: propertyId,
        type,
        booking_type_id: bookingTypeId
      });

      if (!createObjectData?.id) {
        throw new Error('object id is undefined');
      }

      return createObjectData.id;
    } catch (error) {
      console.error('🚀 ~ file: booking.js ~ createObject ~ e', error);
      this.$sentry.captureException(error);
    }
  },

  /**
   * Создание сделки через закрепление клиента
   **/
  async createBookingByPinning ({ rootGetters }, payload) {
    try {
      const {
        role
      } = rootGetters;
      const url = this.$api.booking.createBooking(role);

      const {
        data
      } = await this.$axios.post(url, payload);

      return {
        bookingId: data.id
      };
    } catch (error) {
      console.error('🚀 ~ file: booking.js ~ createBookingByPinning ~ error', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async bookRealty (_, payload) {
    try {
      const url = 'api/properties/bind';

      return await this.$axios.$patch(url, payload);
    } catch (error) {
      console.error('🚀 ~ file: booking.js ~ bookRealty ~ error', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async unbookingProperty (_, payload) {
    try {
      const url = 'api/properties/unbind';

      await this.$axios.$patch(url, payload);
    } catch (error) {
      console.error('🚀 ~ file: booking.js ~ unbookingProperty ~ error', error);
      this.$sentry.captureException(error);
      throw error;
    }
  }
};

export const mutations = {
  SET_INITIAL_DATA (state) {
    state.currentStepIndex = 0;
  },

  SET_MODAL_INITIAL_DATA (state) {
    state.currentModalStepIndex = 0;
  },

  SET_CURRENT_STEP_INDEX (state, payload) {
    state.currentStepIndex = payload;
  },

  SET_CURRENT_MODAL_STEP_INDEX (state, payload) {
    state.currentModalStepIndex = payload;
  },

  SET_BOOKING_RESULT (state, payload) {
    state.bookingResult = payload;
  }
};
