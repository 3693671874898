import { defineStore } from 'pinia';
import { useContext } from '@nuxtjs/composition-api';

export interface AddFavoriteRequest {
  area: number;
  floor: number;
  number: number;
  profitbaseId: number;
  article: string;
  citySlug: string;
  projectSlug: string;
}

export const usePropertyStore = defineStore('property', () => {
  const {
    $sentry,
    $axios
  } = useContext();

  async function getPropertyFavorite (profitbaseId: number): Promise<boolean | undefined> {
    try {
      const url = `/api/properties/article/like/${ profitbaseId }`;
      const {
        data
      } = await $axios.get<boolean>(url);

      return data;
    } catch (error) {
      console.log('🚀 ~ file: property.ts ~ getPropertyFavorite ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function addPropertyFavorite (payload: AddFavoriteRequest): Promise<void> {
    try {
      const url = '/api/properties/article/like';
      await $axios.post<boolean>(url, {
        ...payload
      });
    } catch (error) {
      console.log('🚀 ~ file: property.ts ~ addPropertyFavorite ~ error', error);
      $sentry.captureException(error);
    }
  }

  async function deletePropertyFavorite (profitbaseId: number): Promise<void> {
    try {
      const url = `/api/properties/article/like/${ profitbaseId }`;
      await $axios.delete<boolean>(url);
    } catch (error) {
      console.log('🚀 ~ file: property.ts ~ deletePropertyFavorite ~ error', error);
      $sentry.captureException(error);
    }
  }

  return {
    getPropertyFavorite,
    addPropertyFavorite,
    deletePropertyFavorite
  };
});
